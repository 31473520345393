import $ from "jquery";

import Theme from "./components/Theme";
import Logo from "./components/Logo";
import Page from "./components/Page";
import { Video } from "./components/Video";

const theme = "default";

Theme($(".js--Theme"), theme);
Logo($(".js--Logo"), theme);
Video();
Page("ContactPage");
