import Player from "@vimeo/player";

export const Video = () => {
  const iframe = document.getElementById("js--Player");
  const button = document.querySelector(".js--Mute");

  const player = new Player(iframe);

  button.addEventListener("click", async () => {
    const muted = await player.getMuted();
    player.setMuted(!muted);
    button.innerHTML = muted ? "Mute" : "Unmute";
  });
};
