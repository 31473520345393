const PERIOD = 750;

export default ($el, name = "Default") => {
  const setPosition = () => {
    const x = Math.floor(Math.random() * -$el.width());
    const y = Math.floor(Math.random() * -$el.height());

    $el.css({
      transform: `translate(${x}px, ${y}px) rotate(${Math.random() * 180}deg)`
    });
  };

  $el.attr("data-logo", name);

  setPosition();
  return setInterval(setPosition, PERIOD);
};
