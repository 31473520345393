import $ from "jquery";

export default (name) => {
  const $link = $(`.js--${name}__link`);
  const $page = $(`.js--${name}`);
  const $close = $page.find(`.js--${name}__close`);

  if ($link.length + $page.length + $close.length !== 3) {
    throw new Error("Check your selectors");
  }

  $link.on("click", (e) => {
    e.preventDefault();
    $page.addClass("Page--visible");
  });

  $close.on("click", (e) => {
    e.preventDefault();
    $page.removeClass("Page--visible");
  });
};
